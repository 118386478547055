@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.blocks {
  height: 100%;
}

hr,
.block-block_quote,
.block-two_columns,
.block-three_columns {
  clear: both;
}

.block-two_columns,
.block-three_columns {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.block-button {
  padding: 2rem 1rem;
  @extend %container;

  .block-grid & {
    padding: 2rem 0;
  }
}

.richtext-content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  hr {
    margin: 1rem 0;

    @include mediaQuery($min, $lg) {
      margin: 1.5rem 0;
    }

    @include mediaQuery($min, $xl) {
      margin: 3rem 0;
    }
  }
}

// ============================
// Images
// ============================

.richtext-image {
  margin: 1rem 0;
  max-width: 100%;
  height: auto;

  @include mediaQuery($min, $md) {
    &.right {
      float: right;
      margin-right: 0;
      margin-left: 1rem;
    }

    &.left {
      float: left;
      margin-left: 0;
      margin-right: 1rem;
    }
  }

  &.full-width {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.block-image {
  margin-bottom: 1rem;

  img {
    max-width: 100%;
    height: auto;
  }

  &__info {
    padding-top: 0.5rem;
  }

  &__attribution {
    text-align: right;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

// ============================
// Gallery
// ============================

.gallery {
  &__img {
    a {
      background: fade-out($black, 0.97);

      &:hover {
        text-decoration: none;
      }
    }

    .card-img-top {
      max-height: 360px;
    }

    .card-footer {
      flex: 1;
    }
  }
}

// ============================
// Tables
// ============================

.block-table {
  table {
    width: 100%;
  }
}

// ============================
// Embeds
// ============================

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// ============================
// Accordion
// ============================

.accordion__btn .fa-minus {
  display: none;
}

// ============================
// Featured Image
// ============================

.featuredimage {
  object-fit: cover;
  object-position: center;
  height: 65vh !important;
  width: 100% !important;

  &__wraper {
    max-height: 50vh;
  }
}

// ============================
// Featurettes
// ============================

.stream-blocks {
  height: 100%;
}

.block-featurette {
  height: 100%;
}

.featurette {
  height: 100%;

  .card-img-top {
    object-fit: cover;
    object-position: center center;
  }
}

// ============================
// Featurettes
// ============================

.slider-wrapper {
  position: relative;
}

.slider {
  height: 75vh;
  overflow: hidden;

  @include mediaQuery($max, $lg) {
    margin-top: 0;
    height: 75vh;
  }

  &__slide {
    position: relative;
    height: 75vh;
    overflow: hidden;
  }

  &__img {
    @extend %absolute-img;
    z-index: -1;
  }

  &__overlay {
    @include mediaQuery($max, $md) {
      padding: 5px;
    }
  }

  &__content {
    background: fade-out($black, 0.25);
  }
}
